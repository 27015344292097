import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { MainListItems, SecondaryListItems } from "./listItems";
import {
  Popover,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import toast from "toasted-notes";

import Title from "./Title";

import icon from "../../../assets/images/logo.png";
import { Reports } from "../Reports/Reports";
import { ReportsDay } from "../Reports/ReportsDay";
import { Goals } from "../Goals/Goals";
import { useAuthState } from "../../../context/auth";
import { ReportsR } from "../Regional/Reports/ReportsR";
import { ReportsRDay } from "../Regional/Reports/ReportsRDay";
import { GoalsR } from "../Regional/Goals/GoalsR";
import { Audit } from "../Audit/Audit";
import { Clients } from "../Clients/Clients";
import { Award } from "../Award/Award";
import { VendorTarget } from "../VendorTarget/VendorTarget";
import { Vendor } from "../Vendor/Vendor";
import { Images } from "../../Images";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.celuweb.com/">
        CELUWEB.COM
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

//
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('#7FBA00');//"#0075bb";

const letterColor = getComputedStyle(document.documentElement).getPropertyValue('#FFFFFF');//"#fff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    background: '#7FBA00',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: '#9cc939',
    color: "#FFFFFF",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    background: primaryColor,
  },
  drawerPaper: {
    background: primaryColor,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 300,
  },
  fixedHeightMin: {
    height: 180,
  },
  notify: {
    color: "#2e1156",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  demo: {
    backgroundColor: primaryColor,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: primaryColor,
  },
  mainListItems: {
    backgroundColor: primaryColor,
    color: letterColor,
    span:{
      color: letterColor,
    }
  },
  divider: {
    backgroundColor: primaryColor,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [route, setRoute] = React.useState(0);

  const [count, setCount] = React.useState(0);
  const [form, setForm] = React.useState(false);

  const { user } = useAuthState();

  useEffect(() => {
    Notification.requestPermission().then(function (result) {
    });
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleRoute = (route) => {
    setRoute(route);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h2"
            variant="h6"
            style={{ color: "#FFFFFF" }}
            noWrap
            className={classes.title}
          >
            {
            // (route == 0 && "Dashboard") ||
              (route == 0 && "Imagenes")
              }
          </Typography>

          {/* <img src={logo} width="100" height="30" style={{ margin: "1em" }} /> */}
          <Typography
            component="h2"
            variant="h6"
            color="initial"
            noWrap
            style={{ margin: "0.5em" }}
          >
            {user.regional ? user.username : "Admin"}
          </Typography>
          {/* <IconButton
            color="inherit"
            onClick={() => {
              setCount(0);
              setRoute(0);
            }}
          >
            <Badge badgeContent={count} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <h3 style={{ color: "#FFFFFF" }}>NUTRESA</h3>
          {/* <h4>Emart</h4> */}
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{<MainListItems handleRoute={handleRoute} />}</List>
        <Divider />
        <List>{<SecondaryListItems open={open} />}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {/* <Title>Ventas del Día</Title> */}
            <Grid container spacing={3}>
              {route == 0 && (
                <>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <Images /> 
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
