import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  Backdrop,
  CircularProgress,
  TableCell,
  TableBody,
  TableHead,
  useTheme,
  InputLabel,
  TextField,
  Button,
  TablePagination,
  IconButton,
} from "@material-ui/core";

import { Axios } from "../../../config/axios";

import { GET_SEASONS, GET_SEASONS_AUDIT } from "../../../constants";
import moment from "moment";
import Select from "react-dropdown-select";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

//Styless
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    width: "100%",
  },
  table: {
    height: "300px",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



export const Audit = () => {
  const classes = useStyles();

  const [audits, setAudits] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(0);
  const [isPagination, setIsPagination] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedSeason, setSelectedSeason] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);



  //Actions
  const handleSelectedSeason = (season, page = 0) => {
    setIsLoading(true);
    console.log("Season seleccionada ", season);
    Axios.get(GET_SEASONS_AUDIT + `?option=0&searchProp=${season}&page=${page + 1}`)
      .then((res) => {
        console.log("Lo que llega: ", res.data);
        if ((page + 1) == 1) {
          setLimit(res.data.countFiles);
        }
        setAudits(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  const handleWritedId = (id) => {
    setIsLoading(true);
    Axios.get(GET_SEASONS_AUDIT + `?option=1&searchProp=${id}&page=1`)
      .then((res) => {
        console.log("Lo que llega: ", res.data.data);
        setAudits(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  //Tabla
  const handleChangePage = (event, newPage) => {

    setCurrentPage(newPage);
    console.log("Pagina : " + newPage);
    handleSelectedSeason(selectedSeason, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  //Requests
  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log("Lo que llega: ", res.data.data);
        // setTotal(calculate(res.data));
        setSeasons(res.data.data.filter(temp => temp.active == 1));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //Get the currents seasons
    getSeasons();
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container
        direction="column"
        spacing={1}
        style={
          {
            height: "700px",
          }
        }>
        <Grid container spacing={1} style={
          {
            paddingBottom: "1rem",
          }
        }>
          <Grid item xs={12} md={6} lg={6} style={
            {
              height: "60px",
            }
          }>
            <InputLabel id="demo-simple-select-label">Temporada</InputLabel>
            <Select
              style={{
                marginTop: "1rem",
                borderRadius: "7px",
                height: "55px",
              }}
              color="#85C1E9"
              options={seasons}
              labelField="name"
              valueField="id"
              values={[{ id: -1, name: "Seleccione una temporada" }]}
              onChange={(value) => {
                setIsPagination(true);
                setCurrentPage(0);
                setSelectedSeason(value[0].id);
                handleSelectedSeason(value[0].id);
              }
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} style={
            {
              marginTop: "1.9rem",
              display: "inline-flex",
              alignContent: "center",
            }
          }>
            <p style={
              {
                padding: "1rem 1rem",
              }
            }>Ó</p>
            <TextField id="searchProp" label="Id" type="search" variant="filled" />
            <Button variant="outlined" color="primary"
              style={
                {
                  padding: ".5rem 1rem",
                  marginBottom: ".8rem",
                }
              }
              onClick={
                () => {
                  setIsPagination(false);
                  const input = document.getElementById('searchProp').value;
                  console.log(input);
                  handleWritedId(input);
                }
              }
            >
              Buscar
            </Button>
          </Grid>

        </Grid>
        <Grid container direction="column">
          <Grid item xs={12}>
            <TableContainer style={{ maxHeight: 470 }}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 70 }}>Region</TableCell>
                    <TableCell style={{ minWidth: 70 }}>Temporada</TableCell>
                    <TableCell style={{ minWidth: 140 }}>Cliente</TableCell>
                    <TableCell style={{ minWidth: 140 }}>Usuario </TableCell>
                    <TableCell style={{ minWidth: 140 }}>Imagen </TableCell>
                    <TableCell style={{ minWidth: 140 }}>Fecha Auditoria</TableCell>
                    <TableCell style={{ minWidth: 140 }}>Fecha Imagen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audits.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <p>{row.region ? row.region : "Nacional"}</p>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <p>{row.seasonName}</p>
                      </TableCell>

                      <TableCell>
                        <p>{row.imagenWareHouse + row.imagenClientId}</p>
                      </TableCell>

                      <TableCell>
                        <p>{row.auditUsername}</p>
                      </TableCell>

                      <TableCell
                        numeric
                        component="a"
                        href="#"
                        onClick={() => window.open("https://reconocimiento-productos.web.app/"+ row.imagenId)}
                        style={{ color: "#2e1156", cursor: "pointer" }}
                      >
                        <p>{row.imagenId}</p>
                      </TableCell>

                      <TableCell>
                        {moment(row.auditDate).format("D/MM/YYYY, h:mm a")}
                      </TableCell>
                      <TableCell>
                        {moment(row.ImagenDate).format("D/MM/YYYY, h:mm a")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {
              (
                isPagination
                &&
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[25]}
                  count={limit}
                  page={currentPage}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )
            }
            {/* <TablePagination
              component="div"
              count={100}
              page={currentPage}
              onPageChange={() => { }}
              onRowsPerPageChange={() => { }}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
