import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Container,
  Typography,
  InputLabel,
  Button,
  Box,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import CachedIcon from "@material-ui/icons/Cached";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InfoIcon from "@material-ui/icons/Info";
import PanToolIcon from "@material-ui/icons/PanTool";
import DeleteIcon from "@material-ui/icons/Delete";

import Select from "react-dropdown-select";

import { Axios } from "../../../config/axios";

import * as XLSX from "xlsx";

import {
  GET_PARETO_REPORTE,
  GET_PARETO_REPORTE_EXCEL,
  GET_SEASONS,
  GET_TABLE_PARETO_EXCEL,
  UPLOAD_DATA_PARETO,
  URL_BASE,
} from "../../../constants";
import { formatPercentaje } from "../../../utils/percentaje";
import { useAuthState } from "../../../context/auth";

import toasted from "toasted-notes";
import { validateExcel } from "../../../utils/validateExcel";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
  },
  container: {
    height: "200px"
  }
}));

export const Clients = () => {
  const classes = useStyles();
  const { user } = useAuthState();

  const [clients, setClients] = useState([]);

  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState("");

  const [selected, setSelected] = useState(false);

  const [type, setType] = useState(0);

  const [loading, setLoading] = useState(false);

  const salesForce = [
    {
      label: "BNA",
      value: "BNA",
    },
    {
      label: "FVI",
      value: "FVI",
    },
  ];

  const [force, setForce] = useState(salesForce[0].value);

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0];
        setSeason(season);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClients = (season) => {
    Axios.get(GET_PARETO_REPORTE + season)
      .then((res) => {
        console.log(res.data);
        setClients(
          user.regional
            ? res.data.filter((x) => x.region == user.regional)
            : res.data
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFile = (e) => {
    const target = e.target;
    const name = target.name;
    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        let json = XLSX.utils.sheet_to_json(workbook.Sheets["CLIENTES"]);
        console.log(json);
        const valid = validateExcel(json, type);
        valid ? uploadData(json) : toasted.notify(`Los datos no son correctos`);
        console.log(valid);
      };
    }
  };

  const uploadData = (data) => {
    setLoading(true);
    const msg = type == 0 ? "agregados" : "eliminados";
    try {
      Axios.post(UPLOAD_DATA_PARETO, {
        usuarioRegistro: user.username,
        type,
        data,
      })
        .then((res) => {
          console.log(res);
          toasted.notify(`Datos ${msg} correctamente`);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toasted.notify(`Error`);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSeasons();
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} className={classes.container} >
        {/* <Grid item xs={12} sm={3} lg={3}>
          <InputLabel id="demo-simple-select-label">
            Fuerza de ventas
          </InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={salesForce}
            labelField="label"
            valueField="value"
            onChange={(value) => {
              setForce(value[0].value);
              getClients(season.id, value[0].value);
            }}
            values={[salesForce[0]]}
          />
        </Grid> */}
        <Grid item xs={12} md={3} lg={3}>
          <InputLabel id="demo-simple-select-label">Temporada</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una temporada" }]}
            onChange={(value) => {
              console.log(value[0]);
              const selectedSeason = value[0];
              setSeason(selectedSeason)
              setSelected(true);
            }}
          />
        </Grid>

        {selected && (
          <Grid item xs={12} sm={9} lg={9}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <input
                type="file"
                name="file"
                id="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFile}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<PanToolIcon />}
                onClick={() => {
                  let url = "/files/ManualClientesPareto.pdf";
                  let a = document.createElement("a");
                  a.target = "_blank";
                  a.href = url;
                  a.click();
                }}
              >
                Manual
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<AddCircleIcon />}
                onClick={() => {
                  setType(0);
                  document.getElementById("file").click();
                }}
              >
                Agregar Pareto
              </Button>
              {/* <Button
     variant="contained"
     className={classes.button}
     startIcon={<CachedIcon />}
     onClick={() => {
       setType(1);
       document.getElementById("file").click();
     }}
   >
     Reemplazar Pareto
   </Button> */}
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setType(2);
                  document.getElementById("file").click();
                }}
              >
                Eliminar Pareto
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CloudDownloadIcon />}
                onClick={() => {
                  let url = URL_BASE + GET_TABLE_PARETO_EXCEL + season.id;
                  let a = document.createElement("a");
                  a.target = "_blank";
                  a.href = url;
                  a.click();
                }}
              >
                Descargar Pareto
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CloudDownloadIcon />}
                onClick={() => {
                  let url = user.regional
                    ? URL_BASE +
                      GET_PARETO_REPORTE_EXCEL +
                      season.id +
                      "/" +
                      user.regional
                    : URL_BASE + GET_PARETO_REPORTE_EXCEL + season.id;
                  let a = document.createElement("a");
                  a.target = "_blank";
                  a.href = url;
                  a.click();
                }}
              >
                Descargar Informe
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box m={2} />
      {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer style={{ maxHeight: 470 }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 70 }}>Region</TableCell>
                  <TableCell style={{ minWidth: 70 }}>Cedi</TableCell>
                  <TableCell style={{ minWidth: 70 }}>Representante</TableCell>
                  <TableCell style={{ minWidth: 70 }}>Objetivo</TableCell>
                  <TableCell style={{ minWidth: 70 }}>Ejecutados</TableCell>
                  <TableCell style={{ minWidth: 140 }}>
                    % Cumplimiento{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.region}</TableCell>
                    <TableCell>{row.cedi}</TableCell>
                    <TableCell>{row.representante}</TableCell>
                    <TableCell>{row.objetivo}</TableCell>
                    <TableCell>{row.ejecutados}</TableCell>
                    <TableCell>
                      {formatPercentaje(row.cumplimiento) + "%"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid> */}
    </>
  );
};
