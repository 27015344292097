import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Grid, Button, InputLabel } from "@material-ui/core";
import {
  GET_REPORTE_PLANTA_EXCEL,
  GET_REPORTE_REGION_EXCEL,
  GET_SEASONS,
  GET_TRACING_REGION_EXCEL,
  OBJETIVES,
  OBJETIVES_DETAIL,
  URL_BASE,
} from "../../../../constants";
import { Axios } from "../../../../config/axios";
import { useAuthState } from "../../../../context/auth";

import ReactExport from "react-export-excel";
import moment from "moment";
import Select from "react-dropdown-select";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row, handleRegion, handlePlanta, setHoja } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setHoja(row.region);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.region}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.objetivo)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.objetivoAcumulado.toFixed(0))}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(row.ejecutados)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en").format(
            row.cumplimiento && row.cumplimiento.toFixed(1)
          ) + "%"}
        </TableCell>
        {/* <TableCell>
          <CloudDownloadIcon
            color="primary"
            onClick={() => handleRegion(row.region)}
            style={{ cursor: "pointer" }}
          />
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="h6"
                gutterBottom
                component="div"
              >
                Sedes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Planta
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Objetivo
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Objetivo Acumulado
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Ejecutados
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="left">
                      Cumplimiento
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detail.map((d, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{d.nombrePlanta}</TableCell>
                      <TableCell align="left">
                        {new Intl.NumberFormat("en").format(d.objetivo)}
                      </TableCell>
                      <TableCell align="left">
                        {new Intl.NumberFormat("en").format(
                          d.objetivoAcumulado.toFixed(0)
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {new Intl.NumberFormat("en").format(d.ejecutados)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("en").format(
                          d.cumplimiento && d.cumplimiento.toFixed(1)
                        ) + "%"}
                      </TableCell>
                      {/* <TableCell>
                        <CloudDownloadIcon
                          color="primary"
                          onClick={() => handlePlanta(d)}
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const GoalsR = () => {
  const [goals, setGoals] = useState([]);
  const [excel, setExcel] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState();
  const [hoja, setHoja] = useState();

  const [selected, setSelected] = useState(false);

  const { user } = useAuthState();

  const salesForce = [
    {
      label: "BNA",
      value: "BNA",
    },
    {
      label: "FVI",
      value: "FVI",
    },
  ];

  const [force, setForce] = useState(salesForce[0].value);

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0].id;
        setSeason(res.data.data[0]);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
        //getObjetives(season, force);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getObjetives = (season) => {
    Axios.get(OBJETIVES + season)
      .then((res) => {
        // console.log(res.data);
        let data = res.data;
        Axios.get(OBJETIVES_DETAIL + season)
          .then((res) => {
            console.log("details ", res.data);
            const details = res.data;
            groupby(data, details);
            console.log(data);
            data = data.filter((x) => x.region == user.regional);
            setGoals(data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const groupby = (data, details) => {
    data.forEach((d) => {
      details.forEach((de) => {
        if (d.region == de.region) {
          let detail = d.detail == null ? [] : d.detail;
          detail.push(de);
          d.detail = detail;
        }
      });
    });
  };

  const handleRegion = (region) => {
    setHoja(region);
    getReporteRegionExcel(season.id, region);
  };

  const handlePlanta = (planta) => {
    getReportePlantaExcel(season.id, planta.cod, hoja + " - " + planta.planta);
  };

  const getReporteRegionExcel = (season, region) => {
    let url = URL_BASE + GET_TRACING_REGION_EXCEL + season + "/" + region;
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  };

  const getReportePlantaExcel = (season, planta, hoja) => {
    let url =
      URL_BASE + GET_REPORTE_PLANTA_EXCEL + season + "/" + planta + "/" + hoja;
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.click();
  };

  useEffect(() => {
    getSeasons();
    //getObjetives();
  }, []);

  return (
    <>
      <Grid container spacing={3} style={{ minHeight: "400px" }}>
        {/* <Grid item xs={12} md={6} lg={3}>
          <InputLabel id="demo-simple-select-label">
            Fuerza de ventas
          </InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={salesForce}
            labelField="label"
            valueField="value"
            onChange={(value) => {
              setForce(value[0].value);
              getObjetives(season.id, value[0].value);
            }}
            values={[salesForce[0]]}
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={6}>
          <InputLabel id="demo-simple-select-label">Temporada</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una temporada" }]}
            onChange={(value) => {
              console.log(value[0]);
              const selectedSeason = value[0];
              setSelected(true);
              setSeason(selectedSeason);
              getObjetives(selectedSeason.id);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <InputLabel id="demo-simple-select-label">
            Region {user.regional}
          </InputLabel>

          {selected && (
            <Button
              variant="contained"
              style={{ marginLeft: "10px", marginTop: "10px" }}
              onClick={() =>
                getReporteRegionExcel(season.id, user.regional, force)
              }
            >
              Seguimiento
            </Button>
          )}
        </Grid>

        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ fontWeight: "bold" }}>Region</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Objetivo</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Objetivo Acumulado
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Ejecutados</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Cumplimiento
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {goals.length > 0 &&
                goals.map((row, i) => (
                  <Row
                    key={i}
                    row={row}
                    handleRegion={handleRegion}
                    handlePlanta={handlePlanta}
                    setHoja={setHoja}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* <ExcelFile
        element={
          <Button
            disabled={!excel.length > 0}
            variant="contained"
            style={{ display: "none" }}
            id="excel"
          >
            Descargar Datos
          </Button>
        }
      >
        <ExcelSheet data={excel} name={hoja}>
          {excel.length > 0 &&
            Object.keys(excel[0]).map((o) => {
              if (o == "date") {
                return (
                  <ExcelColumn
                    label="FECHA CAPTURA"
                    value={(col) =>
                      moment(col.date).format("D/MM/YYYY, h:mm a")
                    }
                  />
                );
              } else if (o == "valida") {
                return (
                  <ExcelColumn
                    label="VALIDA"
                    value={(col) => (col.valida ? "Si" : "No")}
                  />
                );
              } else if (o == "isFVI") {
                return (
                  <ExcelColumn
                    label="FVI"
                    value={(col) => (col.isFVI ? "Si" : "No")}
                  />
                );
              } else {
                return (
                  <ExcelColumn
                    label={o.toUpperCase()}
                    value={(col) =>
                      typeof col[o] === "number"
                        ? col[o].toFixed(1) + " %"
                        : col[o]
                    }
                  />
                );
              }
            })}
         
        </ExcelSheet>
      </ExcelFile> */}
    </>
  );
};
