import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Container,
  Typography,
  InputLabel,
} from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

import clsx from "clsx";
import Title from "../Dashboard/Title";
import { Axios } from "../../../config/axios";
import { getChartsCustomers, getChartsImages } from "../../../utils/charts";
import {
  DASHBOARD,
  DASHBOARD_DAY,
  GET_PLANTS,
  GET_REGION,
  GET_REGIONS,
  GET_SEASONS,
} from "../../../constants";
import AnimatedNumber from "animated-number-react";
import CountUp from "react-countup";
import { formatPercentaje } from "../../../utils/percentaje";

import Select from "react-dropdown-select";
import { calculate } from "../../../utils/total";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 400,
  },
  fixedHeightMin: {
    height: 170,
  },
}));

export const Reports = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState([]);
  const [charts, setCharts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [plants, setPlants] = useState([]);
  const [plant, setPlant] = useState(null)
  const [region, setRegion] = useState(null);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState();
  const [select, setSelect] = useState(false);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixed = clsx(classes.paper, classes.fixedHeightMin);

  const formatValue = (value) => `${formatPercentaje(value)} %`;

  const getRegions = (season) => {
    Axios.get(GET_REGIONS + season)
      .then((res) => {
        console.log(res.data);
        setTotal(calculate(res.data));
        setRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0].id;
        setSeason(res.data.data[0]);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
        // getData(season, null, null);
        //getRegions(season);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = (season, region, planta) => {
    let url = "";
    if (season) {
      url = `${DASHBOARD}?season=${season}`;
      if (region != null && planta == null) {
        url += `&region=${region}`;
      } else if (region != null && planta != null) {
        url += `&region=${region}&idPlanta=${planta}`;
      }
      console.log(url);
    } else {
      if (region == null && planta == null) {
        url = DASHBOARD;
      } else if (region != null && planta == null) {
        url = `${DASHBOARD}?region=${region}`;
      } else if (region != null && planta != null) {
        url = `${DASHBOARD}?region=${region}&idPlanta=${planta}`;
      }
    }

    Axios.get(url)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.values);
        setCharts(res.data.data.charts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlants = (region) => {
    Axios.get(`${GET_PLANTS}?region=${region}`)
      .then((res) => {
        console.log(res.data.data);
        setPlants(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //getRegions();
    getSeasons();
    //getData(null, null);
  }, []);

  const handleRegion = (value) => {
    console.log(value);
    setPlants([]);
    setRegion(value);
    getPlants(value);
    getData(season.id, value, plant);
  };

  const handlePlant = (value) => {
    setPlant(value)
    getData(season.id, region, value);
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: "2rem" }}>
        <Grid item xs={12} md={6} lg={6}>
          <InputLabel id="demo-simple-select-label">Temporada</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una temporada" }]}
            onChange={(value) => {
              const selectedSeason = value[0];
              console.log(selectedSeason)
              setRegion(null)
              setPlant(null)
              setSeason(selectedSeason);
              setSelect(true);
              getData(selectedSeason.id, region, plant)
              getRegions(selectedSeason.id)
            }}
          />
        </Grid>
        {select && (
          <>
            <Grid item xs={12} md={3} lg={3}>
              <InputLabel id="demo-simple-select-label">Regiones</InputLabel>
              <Select
                style={{
                  marginTop: "1rem",
                  borderRadius: "7px",
                  height: "55px",
                }}
                color="#85C1E9"
                options={regions}
                labelField="region"
                valueField="region"
                onChange={(value) => handleRegion(value[0].region)}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <InputLabel id="demo-simple-select-label">Plantas</InputLabel>
              <Select
                style={{
                  marginTop: "1rem",
                  borderRadius: "7px",
                  height: "55px",
                }}
                color="#85C1E9"
                options={plants}
                labelField="nombrePlanta"
                valueField="codPlanta"
                onChange={(value) => handlePlant(value[0].codPlanta)}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#85C1E9", fontWeight: "bold" }}
            >
              <CountUp end={data && data.total} />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Total Fotos
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#5BFF33", fontWeight: "bold" }}
            >
              <CountUp end={data && data.v} />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Cumplen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#FF4633", fontWeight: "bold" }}
            >
              <CountUp end={data && data.f} />
            </Typography>
            <Typography variant="h6" gutterBottom>
              No cumplen
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Paper
            elevation={3}
            className={fixed}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              style={{ color: "#FCA130", fontWeight: "bold" }}
            >
              <AnimatedNumber
                value={data && data.e}
                formatValue={formatValue}
                duration={1200}
              />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Efectividad
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper} elevation={3}>
            <ResponsiveContainer>
              <BarChart
                width={800}
                height={300}
                data={charts}
                margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
              >
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="total" name="Fotos subidas" fill="#85C1E9" />

                <Bar dataKey="sumV" name="Cumplen" fill="#5BFF33" />

                <Bar dataKey="sumF" name="No cumplen" fill="#FF4633" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ maxHeight: 470 }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 70 }}>Region</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Total Fotos </TableCell>
                  <TableCell style={{ minWidth: 140 }}>Cumplen </TableCell>
                  <TableCell style={{ minWidth: 140 }}>No cumplen </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {regions.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      style={{ color: "#2e1156", cursor: "pointer" }}
                      component="th"
                      scope="row"
                    >
                      {row.region}
                    </TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell>{row.sumV}</TableCell>
                    <TableCell>{row.sumF}</TableCell>
                  </TableRow>
                ))}
                <TableRow key={0}>
                  <TableCell
                    style={{ color: "#2e1156", cursor: "pointer" }}
                    component="th"
                    scope="row"
                  >
                    {"="}
                  </TableCell>
                  <TableCell>{total[0]}</TableCell>
                  <TableCell>{total[1]}</TableCell>
                  <TableCell>{total[2]}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
