import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  makeStyles,
  useTheme,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Container,
  Typography,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress,
  IconButton,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { Axios } from "../../../config/axios";

import clsx from "clsx";
import {
  GET_PARETO_REPORTE_EXCEL,
  GET_PREMIACION,
  GET_PREMIACION_EXCEL,
  GET_SEASONS,
  URL_BASE,
} from "../../../constants";
import moment from "moment";

import CloseIcon from "@material-ui/icons/Close";
import { useAuthState } from "../../../context/auth";
import Select from "react-dropdown-select";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    width: "100%",
  },
  table: {
    height: "300px",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const Award = () => {
  const classes = useStyles();
  const { user } = useAuthState();

  const [loading, setLoading] = useState(false);

  const [awards, setAwards] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [season, setSeason] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [selected, setSelected] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(0);

  const getSeasons = () => {
    Axios.get(GET_SEASONS)
      .then((res) => {
        console.log(res.data.data);
        let season = res.data.data[0].id;
        setSeason(season);
        setSeasons(res.data.data.filter(temp => temp.active == 1));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAwards = (season) => {
    setLoading(true);
    Axios.get(GET_PREMIACION + season)
      .then((res) => {
        console.log(res.data);
        const data = res.data;
        const emptyRows =
          rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        setAwards(
          user.regional
            ? data.filter((x) => x.region == user.regional)
            : data
        );
        setEmptyRows(emptyRows);
        // setAwards(
        //   user.regional
        //     ? res.data.data.data.filter((x) => x.region == user.regional)
        //     : res.data.data.data
        // );
        // setWeeks(res.data.data.weeks);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getSeasons();
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} style={{ minHeight: "400px" }}>
        <Grid item xs={12} md={6} lg={6}>
          <InputLabel id="demo-simple-select-label">Temporada</InputLabel>
          <Select
            style={{
              marginTop: "1rem",
              borderRadius: "7px",
              height: "55px",
            }}
            color="#85C1E9"
            options={seasons}
            labelField="name"
            valueField="id"
            values={[{ id: -1, name: "Seleccione una temporada" }]}
            onChange={(value) => {
              const selectedSeason = value[0];
              let array = Array.from(
                { length: selectedSeason.weeks },
                (_, i) => i + 1
              );
              setWeeks(array);
              setSelected(true);
              setSeason(selectedSeason);
              getAwards(selectedSeason.id);
            }}
          />
        </Grid>
        {selected && (
          <Grid item xs={12} md={6} lg={6} justify="center">
            <Button
              variant="contained"
              style={{ marginBottom: "20px" }}
              onClick={() => {
                let url = URL_BASE + GET_PREMIACION_EXCEL + season.id;
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = url;
                a.click();
              }}
            >
              Descargar Datos
            </Button>
          </Grid>
        )}

        {selected && (
          <Grid item xs={12}>
            <TableContainer style={{ maxHeight: 500 }}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 70 }}>Region</TableCell>
                    <TableCell style={{ minWidth: 140 }}>Cedi </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      CódigoCliente{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 140 }}>Código RV </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      Fuerza de ventas{" "}
                    </TableCell>
                    <TableCell style={{ minWidth: 140 }}>
                      Semanas Reportadas{" "}
                    </TableCell>
                    {weeks.map((x) => (
                      <TableCell key={x} style={{ minWidth: 140 }}>
                        Semana {x}{" "}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? awards.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : awards
                  ).map((row, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {row.region}
                      </TableCell>
                      <TableCell>{row.cedi}</TableCell>
                      <TableCell>{row.codigoCliente}</TableCell>
                      <TableCell>{row.vendorCode}</TableCell>
                      <TableCell>{row.salesForce}</TableCell>
                      <TableCell align="center">{row.totalWeek}</TableCell>
                      {weeks.map((x) => (
                        <TableCell key={x} align="left">
                          {row["" + x] != null ? <CloseIcon /> : ""}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={awards.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              labelRowsPerPage="Filas por pagina"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
